import './App.css';

let colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange', 'black', 'gray']
function App() {
  return (
    <div className="App" style={{textAlign: 'center'}}>
        {
            colors.map(function(item, index){
                return <div style={{background: item, width: '200px', height: '40px'}}>{item}</div>
            })
        }
        <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2021001956号-2</a>
    </div>
  );
}

export default App;
